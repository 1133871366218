import React, { useState } from 'react';
import Header from '../components/Header';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import ApplyStep1 from '../components/FormWizard/ApplyStep1';
import ApplyStep2 from '../components/FormWizard/ApplyStep2';
import ApplyStep3 from '../components/FormWizard/ApplyStep3';
import { FaInfoCircle } from 'react-icons/fa';

const Apply = () => {
    const [data, setData] = useState({});
    const [step, setStep] = useState(1);
  
    // Render the form wizard based on the current step
    const renderFormWizard = () => {
      switch (step) {
        case 1:
          return <ApplyStep1 setStep={setStep} setData={setData} data={data} />;
        case 2:
          return <ApplyStep2 setStep={setStep} setData={setData} data={data} />;
        case 3:
          return <ApplyStep3 setStep={setStep} setData={setData} data={data} />;
        default:
          return null;
      }
    };
    const [modalVisible, setModalVisible] = useState(false);
    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };
    
    const hideModal = () => {
        setModalVisible(false);
    };
  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
        <Header />
      
        <div className='container mx-auto'>
            <motion.div
                variants={fadeIn('ip', 0.2)} 
                initial="hidden" whileInView={'show'} 
                viewport={{once: false, amount: 0.4}}  
            className='text-center mb-6'>
                    <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                        Aplică
                    </h1>
                    <em className='font-medium font-italic'>Dacă vrei să faci parte din comunitatea noastră poți lăsa o cerere de angajare</em>
                    <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
            </motion.div>
            <div className='grid grid-cols-12 gap-4'>
                <div className="col-span-12 md:col-span-8 bg-blue-950 rounded-lg p-4">
                    {renderFormWizard()}
                </div>
                <div className="col-span-12 md:col-span-4 bg-blue-950 rounded-lg p-4">
                    <h1 className='text-accent flex items-center font-bold text-2xl'>Cerințe de angajare:</h1>
                    <hr className='w-28 h-1  bg-white border-0 rounded md:my-4'/>
                    <div>
                        <h6 className='text-lg font-medium'>1. Trebuie să deții ETS2</h6>
                        <h6 className='text-lg font-medium'>2. 15+ ani</h6>
                        <h6 className='text-lg font-medium'>3. 100+ ore pe ETS2</h6>
                        <h6 className='text-lg font-medium'>4. Cont de TruckersMP cu o vechime de minim 2 săptămâni</h6>
                        <h6 className='text-lg font-medium'>5. La contul de TruckersMP să aveți pe <span className='font-bold text-green-500'>Public</span> istoria banurilor</h6>
                        <h6 className='text-lg font-medium'>6. Trebuie să fii membru pe discordul nostru</h6>
                        <h6 className='text-lg font-medium'>7. Să ai cei 7 ani de acasă</h6>
                        <h6 className='text-lg font-medium'>8. Să nu ai <span className='text-red-500 font-bold'>BAN</span> pe profilul TruckersMP</h6>
                        <h6 className='text-lg text-red-500 font-bold flex items-center'> <FaInfoCircle className='mr-2'/> Se pot face excepții</h6>
                        <button onClick={toggleModal} type="button" className="w-[100%] mt-4 text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2 uppercase text-center mr-2 mb-2">
                            Citește regulamentul
                        </button>
                        
                    </div>
                </div>
            </div>
            



            
        </div>
        <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden={!modalVisible}
            className={`fixed top-0 left-0 right-0 z-50 w-full h-full overflow-x-hidden overflow-y-auto md:inset-0 ${
                modalVisible ? "block" : "hidden"
            }`}
        >
            <div
                className={`modal-backdrop absolute inset-0 bg-black ${modalVisible ? "bg-opacity-50" : "bg-opacity-0"} transition-opacity`}
            >
                
                <div className="relative w-full max-w-2xl max-h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Regulamentul [RO] Trans Express
                            </h3>
                            <button type="button" onClick={hideModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    <div className="p-6 space-y-6">
                            <p className='text-lg'><strong>1.</strong> Să ai cei 7 ani de acasă (SĂ AI BUN SIMȚ).</p>
                            <p className='text-lg'><strong>2.</strong> Nu faceți reclamă la alte servere de discord pe chat-ul comunitații.</p>
                            <p className='text-lg'><strong>3.</strong> Este interzis spam-ul pe chat și tag-urile (sunt permise tag-urile necesare).</p>
                            <p className='text-lg'><strong>4.</strong> Este interzis să trimiteți filmulețe, poze sau link-uri cu conținut pornografic (se pedepsește cu ban pe server)</p>
                            <p className='text-lg'><strong>5.</strong> Toți membrii comunitații trebuie să aibă microfon și căști pentru comunicare (Dacă ai probleme de auz sau vorbit vei primi un grad special).</p>
                            <p className='text-lg'><strong>6.</strong> Gradele se dau in funcție de activitatea pe server și la convoaie.</p>
                            <p className='text-lg'><strong>7.</strong> Nu faceți reclamații aiurea, acestea vor fi pedepsite cu warn (Ex.: Un anumit șofer mi-a pus fr&acirc;nă bruscă &icirc;n față &icirc;n timpul convoiului și am fost avariat!).</p>
                            <p className='text-lg'><strong>8.</strong> Deodata ce ești la noi in comunitate nu ai voie să fii angajat și &icirc;n alta.</p>
                            <p className='text-lg'><strong>9.</strong> Nu trimiteți poze cu colegii voștri pe orice cameră [Se pedepseste cu un WARN].</p>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
      <div className='h-[20vh]'></div>
    </div>
  );
};

export default Apply;
