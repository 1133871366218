import React from 'react';
import Image from '../assets/camion.png';

import { FaDiscord, FaInstagram, FaTruck } from 'react-icons/fa';

import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

import {  fadeIn } from '../variants';
import { Link } from 'react-router-dom';
const Banner = () => {
  return (
    <section className='min-h-[70vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 
            variants={fadeIn('down', 0.3)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='text-[55px] font-bold leading-[0.8] lg:text-[70px]'>
              [RO] Trans <span className='text-accent'>Expess</span>
            </motion.h1>
            <motion.div 
            variants={fadeIn('down', 0.4)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='mb-3 mt-2 text-[36px] lg:text-[40px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-2'>Conducem</span>
              <TypeAnimation sequence={[
                'oriunde',
                2000,
                'oricând',
                2000
              ]} speed={20} className='text-accent' wrapper='span' repeat={Infinity}/>
            </motion.div>
            <motion.p
             variants={fadeIn('down', 0.5)} 
             initial="hidden" whileInView={'show'} 
             viewport={{once: false, amount: 0.7}} 
             className='mb-8 max-w-lg mx-auto lg:mx-0'
            >
              Suntem o comunitate verificată care se bazează pe respect <br/> Suntem formați din membri de toate categoriile de vârstă și organizăm convoaie săptămânale, dar și curse în limita timpului disponibil.  
            </motion.p>
            <motion.div 
            variants={fadeIn('down', 0.6)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='flex max-w-max gap-x-6 items-center mb-6 lg:mb-12 mx-auto lg:mx-0'
            >
              <Link to={'/apply'}>
                <button className='btn btn-lg'>Aplică</button>
              </Link>
              <Link to="https://discord.rotransexpress.ro">
                <button  className='btn-discord btn-sm'>Discord</button>
              </Link>
            </motion.div>
            <motion.div 
            variants={fadeIn('down', 0.8)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.8}} 
            className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
            >
              <Link to="https://truckersmp.rotransexpress.ro/" target='_blank'>
                <FaTruck  className='max-w-[22px]'/>
              </Link>
              <Link to="https://discord.rotransexpress.ro" target='_blank'>
                <FaDiscord  className='max-w-[22px]'/>
              </Link>
              <Link to="https://instagram.rotransexpress.ro/" target='_blank'>
                <FaInstagram  className='max-w-[22px]'/>
              </Link>
            </motion.div>
          </div>
          <motion.div 
            variants={fadeIn('left', 0.5)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='hidden lg:flex max-w-[320px] lg:max-w-[480px]'
          >
            <img src={Image} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
