import React from 'react';
import Logo from '../assets/logo_rte.png';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <header className='py-5'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        <Link to={'/'}>
          <img className='w-22' src={Logo} alt='' /> 
        </Link>
         <a href='https://hub.rotransexpress.ro/'>
          <button className='btn btn-sm'>Login</button>
         </a>
      </div>
    </div>
  </header>
  );
}; 

export default Header;
