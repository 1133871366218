import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import { fadeIn } from '../variants';

const Members = () => {
  const [membersData, setMembers] = useState([]);


  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch('https://hub.rotransexpress.ro/api/members');
        const data = await response.json();
        let members = Array.isArray(data.members)
          ? data.members : [];
        
        // Sort members by position id
        members = members.sort((a, b) => a.position.order - b.position.order);
        
        setMembers(members);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };
    

    fetchMembers();
  }, []);

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Header />

      <div className="container mx-auto">
        <motion.div
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}}  
            className='text-center mb-6'>
            <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                Membri
            </h1>
            <em className='font-medium font-italic'>Toți membri comunității.</em>
            <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {membersData.map((memberItem, index) => (
            
                        
            <motion.div 
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}} 
            key={index} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-blue-950 dark:border-gray-700">
                <div className="flex flex-col items-center p-10">
                    <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={`${memberItem.avatar}`} alt={`${memberItem.name} avatar`}/>
                    <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{memberItem.name}</h5>
                    <span className="text-sm font-bold" style={{color: memberItem.position.color, backgroundColor: memberItem.position.color+'22', padding: '0.1rem 0.5rem', borderRadius: '0.358rem'}}>{memberItem.position.name}</span>
                </div>
            </motion.div>


          ))}
        </div>
      </div>
      
      <div className='h-[10vh]'></div>
    </div>
  );
};

export default Members;
