import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const ApplyStep3 = ({ setStep, setData, data }) => {

  const handleChange = (e) => {
    setData({ ...data, 
      [e.target.name]: e.target.value, 
      [e.target.email]: e.target.value, 
      [e.target.age]: e.target.value,
      [e.target.discord]: e.target.value,
      [e.target.discord_join]: e.target.value,
      [e.target.unde]: e.target.value,
      [e.target.ets2]: e.target.value, 
      [e.target.ats]: e.target.value, 
      [e.target.tmp]: e.target.value, 
      [e.target.steam]: e.target.value, 
    });
  }; 

  const validateInputs = () => {
    const newErrors = {};
    if (!data.steam || !data.tmp) newErrors.all = 'Introduce toate campurile';

    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      Swal.fire({
        title: 'Error!',
        text: Object.values(newErrors).join('\n'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }
  };
  
  const handleSubmit = async () => {
    if (validateInputs()) {
      try {
        
        await axios.post('https://hub.rotransexpress.ro/api/apply', data);
        
        Swal.fire({
          title: 'Success!',
          text: 'Cererea a fost trimisă cu succes, dacă nu sunteți pe discordul comunității vă rugăm să intrați, că dacă veți fi acceptat un membru staff va lua legătura.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
              window.location.href = "https://discord.rotransexpress.ro";
            }
        })
      } catch (error) {
        Swal.fire({
          title: 'Upsss!',
          text: 'Ceva nu a mers bine, încercați mai târziu, sau luați legătura cu un membru staff pe discord.',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
              window.location.href = "https://discord.rotransexpress.ro";
            }
        })
      }
    }
  };

  return (
    <div className="w-full">
        <h1 className='text-center text-2xl font-bold text-accent mb-5'>Alte Jocuri și DLC-uri</h1>
        
        <div class="relative z-0 w-full mb-6 group">
            <input 
                type="text" 
                value={data.tmp || ''} 
                onChange={handleChange} 
                name="tmp"  
                className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required 
            />
            <label 
                for="ets2" 
                className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
                 Linkul la contul de TruckersMP (Exemplu: https://truckersmp.com/user/2578358)*
            </label>
        </div>
        
        <div class="relative z-0 w-full mb-6 group">
            <input 
                type="text" 
                value={data.steam || ''} 
                onChange={handleChange} 
                name="steam"  
                className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required 
            />
            <label 
                for="ats" 
                className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
                Linkul la contul de Steam (Exemplu: https://steamcommunity.com/id/velescovasile/)*
            </label>
        </div>

        
    
        <button
        className="btn btn-sm mr-3"
        onClick={() => setStep(2)}
      >
        Înapoi
      </button>
      <button
        className="btn btn-sm"
        onClick={handleSubmit}
      >
        Trimite
      </button>
    </div>
  );
};

export default ApplyStep3;
