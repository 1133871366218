import React from 'react';

import Hub from '../assets/hub.png';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { FaRegCheckCircle, FaStar, FaTruck, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const About = () => {

  return (
   <div>
     <motion.section 
       variants={fadeIn('up', 0.5)} 
       initial="hidden" whileInView={'show'} 
       viewport={{once: false, amount: 0.4}}  
     className="">
        <div className='container py-8 px-4 mx-auto mt-[12vh]'>
          <motion.div
            variants={fadeIn('up', 0.5)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}}  
          className='text-center mb-6'>
                <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                    Despre noi
                </h1>
                <em className='font-medium font-italic'>De ce să te alături comunității noastre</em>
                <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
          </motion.div>
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
              <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                  <motion.div
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.4}} >
                      <div className="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-blue-900 p-3">
                        <FaTruck className='text-accent font-bold text-4xl'/>
                      </div>
                      <h3 className="mb-2 text-xl font-bold dark:text-white">Convoaie</h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Comunitatea noastră participă și organizează în fiecare vineri și sâmbătă convoaie unde petrecem timpul împreună, timp de 3 ani am organizat peste 300 de convoaie.
                      </p>
                  </motion.div>
                  <motion.div
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.5}} >
                      <div className="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-blue-900 p-3">
                        <FaUsers className='text-accent font-bold text-4xl'/>
                      </div>
                      <h3 className="mb-2 text-xl font-bold dark:text-white">Prieteni</h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Ne străduim să acceptăm și să avem doar oameni prietenoși în comunitatea noastră pentru a face din aceasta cea mai bună experiență posibilă pentru toată lumea! Simțim că am realizat acest lucru până acum și vom continua.
                      </p>
                  </motion.div>
                  <motion.div
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.6}} >
                      <div className="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-blue-900 p-3">
                        <FaStar className='text-accent font-bold text-4xl'/>
                      </div>
                      <h3 className="mb-2 text-xl font-bold dark:text-white">STAFF Dedicat</h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Avem un număr dedicat și prietenos de membri ai personalului care ajută membrii și fiecare încearcă să înbunătățească comunitatea. 
                      </p>
                  </motion.div>
              </div>
          </div>

          <div className="gap-8 py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <motion.img 
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.3}} 
                  src={Hub} 
                />
              <div className="mt-4 md:mt-0">
                  <motion.h2 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}} 
                    className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-accent">Tehnologia noastră</motion.h2>
                  <motion.p 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.5}} 
                    className="mb-6 font-light text-white-500 md:text-lg dark:text-white-400 indent-5">
                    Comunitatea noastră a dezvoltat un Driver Hub care face comunitaea și jocul mai interesant. Driver Hub-ul nostru are multe funcții, printre care:
                  </motion.p>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.6}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Sistem de urmărire a livrărilor</p>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.7}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Sistemul de convoaie</p>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.8}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Sistemul de contracte</p>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.8}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Sistemul de ranguri</p>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.7}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Clasamente</p>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn('up', 0.5)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.7}} 
                    src={Hub} 
                    className="max-w-sm"
                    >
                      <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Sistemul de finanțe și amenzi</p>
                    </motion.div>
                  </div>
                  <motion.div 
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.7}} 
                  src={Hub} 
                  className="w-full mt-2"
                  >
                    <p className='flex items-center'><FaRegCheckCircle className='text-accent text-2xl mr-2'/> Și multe alte funcții care o sa le poți vedea dacă ni te alături</p>
                  </motion.div>
                 <motion.div
                  variants={fadeIn('up', 0.5)} 
                  initial="hidden" whileInView={'show'} 
                  viewport={{once: false, amount: 0.7}} 
                  >
                  <Link to={'/apply'}>
                    <button className='btn btn-sm md:mt-4'>Aplică</button>
                  </Link>
                 </motion.div>
              </div>
          </div>

              
        </div>
    </motion.section>
   </div>
  );
}; 

export default About;
