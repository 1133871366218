import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
const NewsItem = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const response = await fetch(`https://hub.rotransexpress.ro/api/${id}/news`);
        const data = await response.json();
        console.log(data)
        setNewsItem(data.news);
        
      } catch (error) {
        console.error('Error fetching news item data:', error);
      }
    };

    fetchNewsItem();
  }, [id]);

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
        <Header />
        <div className='container mx-auto'>
        {newsItem && (
            <div>
            <motion.h1 
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}} 
            className='text-accent text-3xl mb-6 text-center font-bold'>
                {newsItem.title}
            </motion.h1>
            <motion.div
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}} 
            >
                <ReactMarkdown>
                    {newsItem.content}
                </ReactMarkdown>
            </motion.div>
            </div>
        )}
        </div>
        
        <div className='h-[20vh]'></div>
    </div>
  );
};

export default NewsItem;
