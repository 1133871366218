import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Stats = () => {
    const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`https://hub.rotransexpress.ro/api/statistici`);
        const data = await response.json();
        setStats(data.data);
      } catch (error) {
        console.error('Error fetching stats item data:', error);
      }
    };

    fetchStats();
  }, []);
  const [animationStarted, setAnimationStarted] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  return (
    <motion.section 
       variants={fadeIn('up', 0.2)} 
       initial="hidden" whileInView={'show'} 
       viewport={{once: false, amount: 0.4}}   
       className="bg-white dark:bg-gray-950 bg-main">
        <div className='container py-8 px-4 mx-auto mt-[12vh]'>
        <motion.div
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.5}}  
            className='text-center mb-6'>
                <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                    Statisticile companiei
                </h1>
                <em className='font-medium font-italic'>Statisticile companiei din această lună</em>
                <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
        </motion.div>
        
            <div className='grid mt-4 grid-cols-1 md:grid-cols-3 gap-4'>
                <motion.div
                    ref={ref} 
                    variants={fadeIn('up', 0.2)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}} 
                    className="p-9 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-blue-950 dark:border-gray-700"
                >
                    <h1 className='text-center uppercase font-bold text-[40px]'>
                    {stats && stats.distance ? (
                        <>
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.distance}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        KM
                        </>
                    ) : (
                        'Loading...'
                    )}
                    </h1>
                    <h3 className='text-center mt-3 text-accent font-bold text-[30px]'>Distanță parcursă</h3>
                </motion.div>
                <motion.div 
                    variants={fadeIn('up', 0.3)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}} 
                    className="p-9 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-blue-950 dark:border-gray-700"
                >
                    <h1 className='text-center uppercase font-bold text-[40px]'>
                    {stats && stats.income ? (
                        <>
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.income}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        €
                        </>
                    ) : (
                        'Loading...'
                    )}    
                    </h1>
                    <h3 className='text-center mt-3 text-accent font-bold text-[30px]'>Profit total</h3>
                </motion.div>
                <motion.div 
                    variants={fadeIn('up', 0.4)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}} 
                    className="p-9 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-blue-950 dark:border-gray-700"
                >
                    <h1 className='text-center uppercase font-bold text-[40px]'>
                    {stats && stats.jobs ? (
                        <>
                        {animationStarted || inView ? (
                            <CountUp
                            start={0}
                            end={stats.jobs}
                            duration={2}
                            decimals={0}
                            separator=","
                            onEnd={() => setAnimationStarted(true)}
                            />
                        ) : (
                            0
                        )}
                        
                        </>
                    ) : (
                        'Loading...'
                    )}
                    </h1>
                    <h3 className='text-center mt-3 text-accent font-bold text-[30px]'>Livrări terminate</h3>
                </motion.div>
            </div>



            
        </div>
    </motion.section>
  );
}; 

export default Stats;
