import React, { useState, useEffect } from 'react';

import { FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Blog = () => {
  const [newsData, setNewsData] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ro', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit' });
    return formatter.format(date);
  };

  useEffect(() => {
    const fetchNews = async () => {
        try {
          const response = await fetch('https://hub.rotransexpress.ro/api/news');
          const data = await response.json();
          const sortedNews = Array.isArray(data.news)
            ? data.news.sort((a, b) => b.id - a.id)
            : [];
          const topThreeNews = sortedNews.slice(0, 3); // Get the first 3 elements
          setNewsData(topThreeNews); // Update this line to set the top 3 news data
        } catch (error) {
          console.error('Error fetching users data:', error);
        }
      };
      

    fetchNews();
  }, []);

  return (
    <section className="">
      <div className="container py-8 px-4 mx-auto mt-[10vh]">
        <motion.div 
        variants={fadeIn('up', 0.2)} 
        initial="hidden" whileInView={'show'} 
        viewport={{once: false, amount: 0.4}}  
        className='text-center mb-6'>
                <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                    Noutăți
                </h1>
                <em className='font-medium font-italic'>Ultimele 3 noutăți ale companiei</em>
                <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
        </motion.div>
          {newsData.map((newsItem, index) => (
                <Link to={`/${newsItem.id}/news`}>
                    <motion.div 
                    variants={fadeIn('up', 0.2)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}}  
                    className="mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2">
                        <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r dark:bg-blue-950 dark:border-gray-700">
                            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                                <h3 className="text-xl font-bold text-accent">{newsItem.title}</h3>
                                <p className="my-4">{newsItem.content_summary}</p>
                            </blockquote>
                            <figcaption className="flex items-center justify-center space-x-3">
                                <div className="space-y-0.5 font-medium dark:text-white text-left">
                                    <div>{newsItem.author}</div>
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                    <div className='flex lg:text-left items-center'><FaClock/>  <span className='ml-1 text-xl'>{formatDate(newsItem.published_at)}</span></div>
                                    </div>
                                </div>
                            </figcaption>    
                        </figure>
                    </motion.div>
                </Link>
          ))}
        </div>
        </section>
  );
};

export default Blog;
