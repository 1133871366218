import React from 'react';
import Swal from 'sweetalert2';

const ApplyStep1 = ({ setStep, setData, data }) => {
    
    

  const handleChange = (e) => {
    setData({ ...data, 
        [e.target.name]: e.target.value, 
        [e.target.email]: e.target.value, 
        [e.target.age]: e.target.value,
        [e.target.discord]: e.target.value,
        [e.target.discord_join]: e.target.value,
        [e.target.unde]: e.target.value,
    });
  };


  const validateInputs = () => {
    const newErrors = {};
    if (!data.name || !data.age || !data.email || !data.discord || !data.unde) newErrors.all = 'Introduce toate campurile';

    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      Swal.fire({
        title: 'Error!',
        text: Object.values(newErrors).join('\n'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }
  };
  const handleClick = () => {
    if (validateInputs()) {
      setStep(2);
    }
  };
  return (
    <div className="w-full">
        <h1 className='text-center text-2xl font-bold text-accent mb-5'>Prezentarea</h1>
        <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
                <input 
                    type="text" 
                    value={data.name || ''} 
                    onChange={handleChange} 
                    name="name"  
                    className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    required 
                />
                <label 
                    for="name" 
                    className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Cum te numești? *
                </label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
                <input 
                    type="number" 
                    value={data.age || ''} 
                    onChange={handleChange} 
                    name="age"  
                    className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    required 
                />
                <label 
                    for="age" 
                    className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Câți ani ai? *
                </label>
            </div>
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-6 group">
                <input 
                    type="email" 
                    value={data.email || ''} 
                    onChange={handleChange} 
                    name="email"  
                    className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    required 
                />
                <label 
                    for="email" 
                    className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Emailul tău *
                </label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
                <input 
                    type="text" 
                    value={data.discord || ''} 
                    onChange={handleChange} 
                    name="discord"  
                    className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" " 
                    required 
                />
                <label 
                    for="discord" 
                    className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                    Discordul tău (Exemplu: Vasile#5959) *
                </label>
            </div>
        </div>
        <div class="relative z-0 w-full mb-6 group">
            <input 
                type="text" 
                value={data.unde || ''} 
                onChange={handleChange} 
                name="unde"  
                className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required 
            />
            <label 
                for="unde" 
                className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
                De unde ai aflat de noi? *
            </label>
        </div>
        <div class="relative z-0 w-full mb-6 group">
            <label for="discord_join" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Ești pe discordul <a target='_blank' rel="noreferrer" href="https://discord.rotransexpress.ro" className='text-green-500 font-bold'>[RO] Trans Express</a> ?</label>
            <select name='discord_join' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-400 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option>Da</option>
                <option>Nu</option>
            </select>
        </div>
    
      <button
        className="btn btn-sm"
        onClick={handleClick}
      >
        Următorul
      </button>
    </div>
  );
};

export default ApplyStep1;
