export const fadeIn = (direction, delay) => {
  // Let's assume "mobile" is any device with a width less than or equal to 768 pixels.
  const isMobile = window.innerWidth <= 768;

  // If it's a mobile device, don't apply any animations.
  if (isMobile) {
    return {
      hidden: {
        y: 0,
        x: 0,
        opacity: 1,
      },
      show: {
        y: 0,
        x: 0,
        opacity: 1,
      },
    };
  }

  // If it's not a mobile device, apply the animations as normal.
  return {
    hidden: {
      y: direction === 'up' ? 80 : direction === 'down' ? -80 : 0,
      opacity: 0,
      x: direction === 'left' ? 80 : direction === 'right' ? -80 : 0,
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 1.2,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};
