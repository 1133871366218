import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const News = () => {
  const [newsData, setNewsData] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ro', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit' });
    return formatter.format(date);
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://hub.rotransexpress.ro/api/news');
        const data = await response.json();
        const sortedNews = Array.isArray(data.news)
          ? data.news.sort((a, b) => b.id - a.id)
          : [];
        setNewsData(sortedNews);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Header />

      <div className="container mx-auto">
      <motion.div
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}}  
            className='text-center mb-6'>
            <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                Noutăți
            </h1>
            <em className='font-medium font-italic'>Toate noutățile comunității.</em>
            <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {newsData.map((newsItem, index) => (
            <motion.div 
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}} 
            key={index} 
            className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-blue-950 dark:border-gray-700 dark:hover:bg-gray-700">
              <Link to={`/${newsItem.id}/news`}>
                <div className='h-[8vh]'>
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{newsItem.title}</h5>
                </div>
                <h6 className='flex lg:text-left items-center'><FaClock/>  <span className='ml-1 text-xl'>{formatDate(newsItem.published_at)}</span></h6>
                <hr className='mt-2 mb-2'/>
                <p className="font-normal text-gray-700 dark:text-gray-400">{newsItem.content_summary.slice(0, 100)}</p>
                
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
      <div className='h-[10vh]'></div>
    </div>
  );
};

export default News;
