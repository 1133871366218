import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { FaCalendarDay, FaMap, FaMapMarked, FaMapMarkedAlt, FaServer } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Convoys = () => {
  const [newsData, setNewsEvents] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ro', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatter.format(date);
  };

 
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://hub.rotransexpress.ro/api/events');
        const data = await response.json();
        const sortedEvents = Array.isArray(data.data)
        ? data.data
        : [];
        const topThreeNews = sortedEvents.slice(0, 3); 
        setNewsEvents(topThreeNews);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    fetchEvents();
  }, []);

  return (

      <div className="container  py-8 px-4 mx-auto mt-[12vh]">
        <motion.div 
        variants={fadeIn('up', 0.2)} 
        initial="hidden" whileInView={'show'} 
        viewport={{once: false, amount: 0.4}}  
        className='text-center mb-6'>
                <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                    Convoaie
                </h1>
                <em className='font-medium font-italic'>Următoarele 3 convoaie la care vom participa</em>
                <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {newsData.map((eventItem, index) => (
            
            <motion.div 
            variants={fadeIn('up', 0.2)} 
            initial="hidden" whileInView={'show'} 
            viewport={{once: false, amount: 0.4}} 
            key={index} 
            className="max-w-sm bg-whiteborder border-gray-200 rounded-lg shadow dark:bg-blue-950 dark:border-gray-700">
                
                <Link to={`/${eventItem.id}/convoy`}>
                    <img class="rounded-t-lg h-[130px] w-[100%]" src={eventItem.banner ?? 'https://static.truckersmp.com/images/event/cover/13385.1674554724.png'} alt="" />
                  </Link>
                <div className="p-5">
                    <Link to={`/${eventItem.id}/convoy`}>
                        <h5 className="mb-2 md:min-h-[10vh] lg:min-h-[7vh] xl:min-h-[5h] text-[16px] lg:text-[22px] font-bold tracking-tight text-gray-900 dark:text-white">{eventItem.title}</h5>
                    </Link>
                    <hr className='mt-2 mb-2'/>
                    <div className='md:min-h-[15vh] sm:min-h-[13vh]'>
                        <h6 className="font-normal flex items-center text-gray-700 dark:text-gray-400">
                            <FaCalendarDay className='mr-2'/> {formatDate(eventItem.date)} {eventItem.meetup_time} UTC
                        </h6>
                        <h6 className="font-normal flex items-center text-gray-700 dark:text-gray-400">
                            <FaMapMarkedAlt className='mr-2'/> {eventItem.start_location}
                        </h6>
                        <h6 className="font-normal flex items-center text-gray-700 dark:text-gray-400">
                            <FaMapMarked className='mr-2'/> {eventItem.end_location}
                        </h6>
                        <h6 className="font-normal flex items-center text-gray-700 dark:text-gray-400">
                            <FaServer className='mr-2'/> {eventItem.server}
                        </h6>
                        <h6 className="font-normal flex items-center text-gray-700 dark:text-gray-400">
                            <FaMap className='mr-2'/> {eventItem.dlc}
                        </h6>
                    </div>
                    
                    <Link to={`/${eventItem.id}/convoy`}>
                      <button type="button" class="w-[100%] mt-4 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 uppercase text-center mr-2 mb-2">
                          Vezi
                      </button>
                    </Link>
                </div>
            </motion.div>

          ))}
        </div>
      </div>
  );
};

export default Convoys;
