import React, { Component } from 'react'

import Banner from '../components/Banner';
import Header from '../components/Header';
import Stats from '../components/Stats';
import Blog from '../components/Blog';
import Convoys from '../components/Convoys';
import About from '../components/About';
import Team from '../components/Team';
export default class Home extends Component {
  render() {
    return (
        <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
        
        <Header />
        <Banner />
        <About />
        <Team />
        <Stats />
        <Convoys />
        <Blog />
        <div className='h-[10vh]'></div>
      </div>
    )
  }
}
