import React, { createContext, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from './pages/Home';
import { BiCalendar, BiHomeAlt, BiNews, BiShieldQuarter } from 'react-icons/bi';
import News from './pages/News';
import NewsItem from './pages/NewsItem';
import { FaUsers } from 'react-icons/fa';
import Events from './pages/Events';
import Privacy from './pages/Privacy';
import Members from './pages/Members';
import ConvoyItem from './pages/ConvoyItem';
import Loader from './components/Loader';
import Apply from './pages/Apply';
import ScrollToTop from './components/ScrollToTop';

// Create a context for the loading state
const LoadingContext = createContext();

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Change this value to control the duration of the loader

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
}

// Create a custom hook to use the loading context
function useLoading() {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
}

function App() {
  return (
    <LoadingProvider>
      <LoadingWrapper />
    </LoadingProvider>
  );
}

function LoadingWrapper() {
  const loading = useLoading();

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <React.StrictMode>
          <Router>
          <div>
            <nav className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
              <div className='container mx-auto'>
                <div className='w-full items-center bg-black/30 h-[76px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex justify-between text-2xl text-white/80'>
                  <NavLink
                    to='/'
                    activeClassName='active'
                    className='cursor-pointer w-[60px] flex items-center h-[60px] justify-center'
                  >
                    <BiHomeAlt />
                  </NavLink>
                  <NavLink
                    to='/news'
                    activeClassName='active'
                    className='cursor-pointer w-[60px] flex items-center h-[60px] justify-center'
                  >
                    <BiNews />
                  </NavLink>
                  
                  <NavLink
                    to='/members'
                    activeClassName='active'
                    className='cursor-pointer w-[60px] flex items-center h-[60px] justify-center'
                  >
                    <FaUsers />
                  </NavLink>
                  <NavLink
                    to='/events'
                    activeClassName='active'
                    className='cursor-pointer w-[60px] flex items-center h-[60px] justify-center'
                  >
                    <BiCalendar />
                  </NavLink>
                  <NavLink
                    to='/privacy'
                    activeClassName='active'
                    className='cursor-pointer w-[60px] flex items-center h-[60px] justify-center'
                  >
                    <BiShieldQuarter />
                  </NavLink>
                  
                </div>
              </div>
            </nav>

            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/news' element={<News />} />
              <Route path='/events' element={<Events />} />
              <Route path="/:id/news" element={<NewsItem/>} />
              <Route path="/:id/convoy" element={<ConvoyItem/>} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/members' element={<Members />} />
              <Route path='/members' element={<Members />} />
              <Route path='/apply' element={<Apply />} />
            </Routes>
          </div>
        </Router>
        </React.StrictMode>
      )}
      </div>
  );
}
export default App;
