import React, {useState} from 'react';
import Swal from 'sweetalert2';
import DLCItaly from '../../assets/dlc/Bella Italy.jpg';
import DLCFrance from '../../assets/dlc/Vive la France.jpg';
import DLCScandinavia from '../../assets/dlc/Scandinavia.jpg';
import DLCGoing from '../../assets/dlc/Going East.jpg';
import DLCBeyond from '../../assets/dlc/Beyond the Baltic.jpg';
import DLCRomania from '../../assets/dlc/Road To The Black Sea.jpg';
import DLCIberia from '../../assets/dlc/Iberia.jpg';

const ApplyStep2 = ({ setStep, setData, data }) => {

  const handleChange = (e) => {
    setData({ ...data, 
      [e.target.name]: e.target.value, 
      [e.target.email]: e.target.value, 
      [e.target.age]: e.target.value,
      [e.target.discord]: e.target.value,
      [e.target.discord_join]: e.target.value,
      [e.target.unde]: e.target.value,
      [e.target.ets2]: e.target.value, 
      [e.target.ats]: e.target.value, 
    });
  }; 

  const [dlc, setDlc] = useState([]);

  const handleDlcChange = (event) => {
    if (event.target.checked) {
      setDlc([...dlc, event.target.value]);
    } else {
      setDlc(dlc.filter(item => item !== event.target.value));
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!data.ets2 || !data.ats) newErrors.all = 'Introduce toate campurile';

    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      Swal.fire({
        title: 'Error!',
        text: Object.values(newErrors).join('\n'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return false;
    }
  };
  const handleClick = () => {
    if (validateInputs()) {
      setData(prevData => ({ ...prevData, dlc })); // Update the data state with dlc
      setStep(3);
    }
  };

  return (
    <div className="w-full">
        <h1 className='text-center text-2xl font-bold text-accent mb-5'>Alte Jocuri și DLC-uri</h1>
        
        <div class="relative z-0 w-full mb-6 group">
            <input 
                type="text" 
                value={data.ets2 || ''} 
                onChange={handleChange} 
                name="ets2"  
                className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required 
            />
            <label 
                for="ets2" 
                className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
                Câte ore ai în Euro Truck Simulator 2? *
            </label>
        </div>
        
        <div class="relative z-0 w-full mb-6 group">
            <input 
                type="text" 
                value={data.ats || ''} 
                onChange={handleChange} 
                name="ats"  
                className="block py-2.5 px-0 w-full text-lg text-white-900 bg-transparent border-0 border-b-2 border-white-300 appearance-none dark:text-white dark:border-white-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" " 
                required 
            />
            <label 
                for="ats" 
                className="peer-focus:font-medium absolute text-lg text-white-500 dark:text-white-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
                Ai American Truck Simulator, dacă da câte ore ai? *
            </label>
        </div>
          <label for="discord_join" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Alege ce DLC-uri ai de mai jos</label>
          <ul class="grid w-full gap-6 md:grid-cols-3 mb-5">
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCItaly" name="dlc[]"  value="Bella Italy" class="hidden peer"  />
                  <label for="DLCItaly" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCItaly} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCFrance" name="dlc[]" value="Vive la France" class="hidden peer"  />
                  <label for="DLCFrance" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCFrance} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCScandinavia" name="dlc[]" value="Scandinavia" class="hidden peer"  />
                  <label for="DLCScandinavia" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCScandinavia} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCGoing" name="dlc[]" value="Going East" class="hidden peer"  />
                  <label for="DLCGoing" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCGoing} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCBeyond" name="dlc[]" value="Beyond the Baltic Sea" class="hidden peer" />
                  <label for="DLCBeyond" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCBeyond} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCRomania" name="dlc[]" value="Road To The Black Sea" class="hidden peer"  />
                  <label for="DLCRomania" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCRomania} alt="" />
                  </label>
              </li>
              <li>
                  <input type="checkbox" onChange={handleDlcChange} id="DLCIberia" name="dlc[]" value="Iberia" class="hidden peer" />
                  <label for="DLCIberia" class="inline-flex items-center justify-between w-full p-2  border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                      <img src={DLCIberia} alt="" />
                  </label>
              </li>
          </ul>

        
    
        <button
        className="btn btn-sm mr-3"
        onClick={() => setStep(1)}
      >
        Înapoi
      </button>
      <button
        className="btn btn-sm"
        onClick={handleClick}
      >
        Următorul
      </button>
    </div>
  );
};

export default ApplyStep2;
