import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';


import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { FaClock, FaInfoCircle, FaMap, FaMapMarked, FaMapMarkedAlt, FaRegClock, FaRoad, FaServer, FaTruck, FaUser, FaUsers } from 'react-icons/fa';
const ConvoyItem = () => {
  const { id } = useParams();
  const [convoyItem, setConvoyItem] = useState(null);
  const [upcomingConvoys, setUpcomingConvoys] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('ro', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatter.format(date);
  };
  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const response = await fetch(`https://hub.rotransexpress.ro/api/${id}/convoy`);
        const data = await response.json();
        console.log(data)
        setConvoyItem(data.data);
        
      } catch (error) {
        console.error('Error fetching news item data:', error);
      }
    };
    const fetchUpcomingConvoys = async () => {
        try {
          const response = await fetch(`https://hub.rotransexpress.ro/api/${id}/getUpcomingConvoys`);
          const data = await response.json();
          setUpcomingConvoys(data.data);
        } catch (error) {
          console.error('Error fetching upcoming convoys:', error);
        }
      };
  
      fetchUpcomingConvoys();
    fetchNewsItem();
  }, [id]);

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
        <Header />
        <div className='container mx-auto'>
        {convoyItem && (
            <div>
                <motion.h1 
                    variants={fadeIn('up', 0.2)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.4}} 
                    className='text-accent text-3xl mb-6 text-center font-bold'
                >
                    {convoyItem.convoy.title}
                </motion.h1>
                <motion.div 
                    variants={fadeIn('up', 0.4)} 
                    initial="hidden" whileInView={'show'} 
                    viewport={{once: false, amount: 0.1}} 
                    className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-8 bg-blue-950 rounded-lg p-4">
                        <ReactMarkdown>
                            {convoyItem.tmp.description}
                        </ReactMarkdown>
                    </div>
                    <div className="col-span-12 md:col-span-4 bg-blue-950 rounded-lg p-4">
                        <h1 className='flex items-center'>
                            <FaUser /> <span className='ml-2 text-accent text-[20px] lg:text-2xl font-bold '>Organizator</span>
                        </h1>
                       <hr className='mt-2 mb-2'/>
                        <Link to={convoyItem.tmp.vtc.website} target='_blank'>
                            <img className="rounded mx-auto w-36 h-36" src={convoyItem.tmp.vtc.logo} alt="Extra large avatar"></img>
                            <div className='text-center'>
                                <h2 className='text-[15px] md:text-[20px] font-bold'>
                                {
                                    (() => {
                                            if(convoyItem.tmp.vtc.verified !== '') {
                                                if(convoyItem.tmp.vtc.verified === true) {
                                                    return (
                                                        <span style={{color: '#f44336'}}>
                                                            {convoyItem.tmp.vtc.name}
                                                        </span>
                                                    );
                                                } else {
                                                      return (
                                                        <span style={{color: '#daa520'}}>
                                                            {convoyItem.tmp.vtc.name}
                                                        </span>
                                                      );      
                                                }
                                            } else {
                                               return (
                                                <span style={{color: convoyItem.tmp.vtc.color}}>
                                                    {convoyItem.tmp.vtc.name}
                                                </span>
                                               );
                                            }
                                        })()  
                                    }  
                                    
                                    
                                </h2> 
                                <em className='text-[13px] md:text-[15px] font-italic'>{convoyItem.tmp.vtc.slogan}</em>
                            </div>
                        </Link>
                        <h1 className='flex items-center'>
                            <FaInfoCircle /> <span className='ml-2 text-accent text-[20px] lg:text-2xl font-bold '>Informații convoi</span>
                        </h1>
                       <hr className='mt-2 mb-2'/>
                        <div className='text-left'>
                            <h3 className='flex items-center'><FaClock /> <span className='ml-2'>Ora parcare: {convoyItem.convoy.meetup_time} UTC</span></h3>
                            <h3 className='flex items-center'><FaRegClock /> <span className='ml-2'>Ora pornire: {convoyItem.convoy.departure_time} UTC</span></h3>
                            <h3 className='flex items-center'><FaMapMarkedAlt /> <span className='ml-2'>Parcarea: {convoyItem.convoy.start_location}</span></h3>
                            <h3 className='flex items-center'><FaMapMarked /> <span className='ml-2'>Destinație: {convoyItem.convoy.end_location}</span></h3>
                            <h3 className='flex items-center'><FaMap /> <span className='ml-2'>DLC: {convoyItem.convoy.dlc}</span></h3>
                            <h3 className='flex items-center'><FaServer /> <span className='ml-2'>Server: {convoyItem.convoy.server}</span></h3>
                            <h3 className='flex items-center'><FaRoad /> <span className='ml-2'>Distanța: {convoyItem.convoy.distance} KM</span></h3>
                        
                            <Link to={convoyItem.convoy.route} target='_blank'>
                                <button type="button" className="w-[100%] mt-4 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 uppercase text-center mr-2 mb-2">
                                    Ruta
                                </button>
                            </Link>
                            <h1 className='flex items-center'>
                                <FaUsers /> <span className='ml-2 text-accent text-[20px] lg:text-2xl font-bold '>Participanți</span>
                            </h1>
                            <hr className='mt-2 mb-2'/>
                            <h3>{convoyItem.tmp.confirmed} utilizatori</h3>
                            <h3>{convoyItem.tmp.vtcs} companii</h3>
                            <Link to={convoyItem.convoy.tmp} target='_blank'>
                                <button type="button" className="w-[100%] mt-4 text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2 uppercase text-center mr-2 mb-2">
                                    Vreau să particip
                                </button>
                            </Link>
                                <h1 className='flex items-center'>
                                    <FaTruck /> <span className='ml-2 text-accent text-[20px] lg:text-2xl font-bold '>Următoarele 3 convoaie</span>
                                </h1>
                            {upcomingConvoys.map(convoy => (
                                <div key={convoy.id}>
                                <hr className='mt-2 mb-2'/>
                                <Link to={`/${convoy.id}/convoy`}>
                                    <img class="rounded-lg h-[7vh] w-[100%]" src={convoy.banner ?? 'https://static.truckersmp.com/images/event/cover/13385.1674554724.png'} alt="" />
                                </Link>
                                <Link to={`/${convoy.id}/convoy`}>
                                    <h2 className='text-md font-bold text-accent'>{convoy.title}</h2>
                                </Link>
                                <p>{formatDate(convoy.date)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </motion.div>               
            </div>
        )}
        </div>
        
        <div className='h-[20vh]'></div>
    </div>
  );
};

export default ConvoyItem;
