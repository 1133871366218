import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';


import { FaSteam, FaTruck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { fadeIn } from '../variants';

const Team = () => {
    const [membersData, setMembers] = useState([]);


    useEffect(() => {
        const fetchMembers = async () => {
        try {
            const response = await fetch('https://hub.rotransexpress.ro/api/members/staff');
            const data = await response.json();
            let members = Array.isArray(data.members)
            ? data.members : [];
            
            // Sort members by position id
            members = members.sort((a, b) => a.position.order - b.position.order).slice(0, 8);
            
            setMembers(members);
        } catch (error) {
            console.error('Error fetching users data:', error);
        }
        };
        

        fetchMembers();
    }, []);
    return (
      <div>
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
            <motion.div
                variants={fadeIn('up', 0.2)} 
                initial="hidden" whileInView={'show'} 
                viewport={{once: false, amount: 0.4}}  
                className='text-center mb-6'>
                <h1 className='text-[26px] mb-2 md:text-[40px] text-accent font-bold'>
                    Echipa noastră
                </h1>
                <em className='font-medium font-italic'>În prezent, avem o echipă de profesioniști dedicați care lucrează pentru a înbunătăți comunitatea noastră.</em>
                <hr className='w-28 h-2 mx-auto  bg-yellow-100 border-0 rounded md:my-4 dark:bg-yellow-500'/>
            </motion.div>
                <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {membersData.map((memberItem, index) => (
                        <motion.div 
                        variants={fadeIn('up', 0.2)} 
                        initial="hidden" whileInView={'show'} 
                        viewport={{once: false, amount: 0.1 + (index * 0.1)}}  
                        key={index} className="text-center text-gray-500 dark:text-gray-400">
                            <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={`${memberItem.avatar}`} alt="Helene Avatar"></img>
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                {memberItem.name}
                            </h3>
                            
                            <p><span className="text-sm font-bold" style={{color: memberItem.position.color, backgroundColor: memberItem.position.color+'22', padding: '0.1rem 0.5rem', borderRadius: '0.358rem'}}>{memberItem.position.name}</span></p>
                            <ul className="flex justify-center mt-4 space-x-4">
                                <li>
                                    <Link to={memberItem.tmp} target="_blank" rel="noopener noreferrer" className="text-[#741619] hover:text-gray-900 dark:hover:text-white">
                                        <FaTruck className="w-6 h-6"/>
                                    </Link> 
                                </li>
                                <li> 
                                    <Link to={memberItem.steam} target="_blank" rel="noopener noreferrer" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                                        <FaSteam className="w-6 h-6"/>
                                    </Link> 
                                </li>
                            </ul>
                        </motion.div>
                    ))}
                </div>
            </div>
      </div>
    )
  };
  export default Team;
